<div class="container">
  <h1>Create New Game</h1>
  <form class="form-horizontal" (ngSubmit)="onSubmit()" #newGameForm="ngForm">
    <pydt-configure-game
      *ngIf="model"
      [model]="model"
      [selectedCivs]="[model?.player1Civ.leaderKey]"
    ></pydt-configure-game>
    <div class="form-group">
      <label for="selectCiv" class="col-sm-3 control-label">Select Your Civ:</label>
      <pydt-select-civ
        *ngIf="curCiv"
        [curCiv]="curCiv"
        [randomOnly]="model.randomOnly"
        [leaders]="filteredLeaders()"
        class="col-sm-9"
        (selectedCiv)="selectedCivChange($event)"
      ></pydt-select-civ>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-3 col-sm-9">
        <button type="submit" class="btn btn-primary" [disabled]="!newGameForm.form.valid">Create Game!</button>
      </div>
    </div>
  </form>
</div>

<div
  bsModal
  #mustSetEmailModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mustSetEmailModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" [routerLink]="['user/profile']">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">No Notification Email Set</h4>
      </div>
      <div class="modal-body">
        Creating a game implies more responsibility than simply joining a game - please set your notification email
        before you create the game so we can send you updates about the status of your game.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [routerLink]="['/user/profile']">OK</button>
      </div>
    </div>
  </div>
</div>
