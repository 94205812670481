<ng-container>
  <div class="row">
    <div class="col-sm-12">
      <p>
        You can only kick a player if they have had 24 hours to play their turn and have failed to do so. Please do not
        kick players without reason - if complaints are logged against you for kicking players from games
        inappropriately you may be banned from the site!
      </p>
    </div>
    <ng-container *ngIf="substituteUsers?.length">
      <div class="col-sm-12">
        <p>
          Players with more than 500 turns played can register to be substituted in to games when players leave. If this
          game is still competitive, you can substitute in another player to take over for the player you're removing.
        </p>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                style="top: -5px"
                [checked]="!!userToSubstitute"
                (click)="userToSubstitute = userToSubstitute ? null : randomizeUserToSubstitute()"
              />
              Do you want to substitute another player into this slot?
            </label>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="userToSubstitute">
        <label for="description" class="col-sm-3 control-label">Player to Substitute:</label>
        <div class="col-sm-9">
          <select class="form-control" [(ngModel)]="userToSubstitute">
            <option *ngFor="let user of substituteUsers" [ngValue]="user">
              {{ user.displayName }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row" style="margin-top: 20px">
    <div class="col-xs-12 text-center">
      <button type="button" class="btn btn-danger" (click)="confirmKickUserModal.show()">Kick Player</button>
    </div>
  </div>
</ng-container>

<div
  bsModal
  #confirmKickUserModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmKickUserModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="confirmKickUserModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Are you sure?</h4>
      </div>
      <div class="modal-body">
        Are you sure you want to kick the current player from {{ game?.displayName }}?
        <b>THIS ACTION IS IRREVERSIBLE!</b>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="confirmKickUserModal.hide()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="kickPlayer()">Kick Player!</button>
      </div>
    </div>
  </div>
</div>
