<div class="panel panel-default">
  <div class="panel-heading">Start Game</div>
  <div class="panel-body">
    <div *ngIf="game.players.length < 2">
      <p>You can't start the game until you have at least 2 human players registered.</p>
    </div>
    <div *ngIf="game.players.length > 1">
      <p>When you're ready to start the game, click the button below!</p>
      <div class="row">
        <div class="col-xs-12 text-center">
          <button type="button" class="btn btn-primary" (click)="confirmStartGameModal.show()">Start Game!</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #confirmStartGameModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmStartGameLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="confirmStartGameModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Are you sure?</h4>
      </div>
      <div class="modal-body">
        Are you sure you're ready to start this game with <b>{{ game?.players.length }}</b> human players?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="confirmStartGameModal.hide()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmStartGameModal.hide(); startGame()">
          Start the Game!
        </button>
      </div>
    </div>
  </div>
</div>
