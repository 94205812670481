<ng-container>
  <div class="row">
    <div class="col-sm-12">
      <p>
        This option is mostly for players who switch back and forth between PYDT and normal online play. If your game
        saves have got ahead of where PYDT thinks the game is, press the button below to allow the next turn upload to
        reset the state of the game. If you're having general turn issues it's probably better
        <strong>NOT</strong> to use this button and ask for help in the Game Support area of the forum.
      </p>
    </div>
  </div>
  <div class="row" style="margin-top: 20px" *ngIf="!game.resetGameStateOnNextUpload">
    <div class="col-xs-12 text-center">
      <button type="button" class="btn btn-default" (click)="resetGameStateOnNextUpload()">
        Enable Game State Reset
      </button>
    </div>
  </div>
  <div class="row" style="margin-top: 20px" *ngIf="!!game.resetGameStateOnNextUpload">
    <div class="col-xs-12 text-center">
      <h4><strong>Game State Reset Enabled!</strong></h4>
    </div>
  </div>
</ng-container>
