<span
  *ngIf="!canEditTurnOrder && !!game.hashedPassword"
  tooltip="This game is password protected."
  placement="left"
  class="glyphicon glyphicon-lock pull-right ml"
></span>
<button
  class="btn btn-sm btn-default pull-right ml"
  *ngIf="canEditTurnOrder && !editingTurnOrder"
  type="button"
  (click)="startEditingTurnOrder()"
>
  Edit Turn Order
</button>
<button class="btn btn-sm btn-primary pull-right ml" *ngIf="editingTurnOrder" type="button" (click)="saveTurnOrder()">
  Save Turn Order
</button>
<button
  class="btn btn-sm btn-default pull-right ml"
  *ngIf="editingTurnOrder"
  type="button"
  (click)="sortViaPlayTimes()"
>
  Sort via Play Times
</button>
<p *ngIf="showTurnInfo && game.gameTurnRangeKey > 1" class="pull-right ml">
  Round {{ game.round }} - {{ lastTurnText$ | async }}
</p>
<h3 [class.yourTurn]="game.inProgress && activeProfile?.steamid === game.currentPlayerSteamId">
  <img
    src="/img/game_{{ game.gameType }}.png"
    style="height: 25px; padding-right: 10px; position: relative; top: -3px"
    [tooltip]="civGame?.displayName"
  />
  {{ gameTitle }}
</h3>
<div class="row" style="padding-left: 13px; padding-right: 13px">
  <ng-container *ngIf="editingTurnOrder">
    <div class="col-{{ game.slots }}-slots">
      <pydt-player-avatar
        [game]="game"
        [players]="gamePlayers"
        [gamePlayerProfiles]="gamePlayerProfiles"
        [civDefs]="civDefs"
        [index]="0"
        [thumbnailOnly]="true"
        size="BIG"
      ></pydt-player-avatar>
    </div>
    <div
      dragula="players"
      [dragulaModel]="reorderableIndexes$ | async"
      (dragulaModelChange)="reorderableIndexes$.next($event)"
    >
      <div *ngFor="let index of reorderableIndexes$ | async" class="col-{{ game.slots }}-slots">
        <pydt-player-avatar
          [game]="game"
          [players]="gamePlayers"
          [gamePlayerProfiles]="gamePlayerProfiles"
          [civDefs]="civDefs"
          [index]="index"
          [thumbnailOnly]="true"
          [thumbnailOnlyDragMode]="true"
          size="BIG"
        ></pydt-player-avatar>
      </div>
    </div>
    <div *ngFor="let index of emptyIndexes" class="col-{{ game.slots }}-slots">
      <pydt-player-avatar
        [game]="game"
        [players]="gamePlayers"
        [gamePlayerProfiles]="gamePlayerProfiles"
        [civDefs]="civDefs"
        [index]="index"
        [thumbnailOnly]="true"
        size="BIG"
      ></pydt-player-avatar>
    </div>
  </ng-container>
  <ng-container *ngIf="!editingTurnOrder">
    <div *ngFor="let player of gamePlayers; let i = index" class="col-{{ game.slots }}-slots">
      <pydt-player-avatar
        [game]="game"
        [players]="gamePlayers"
        [gamePlayerProfiles]="gamePlayerProfiles"
        [civDefs]="civDefs"
        [index]="i"
        size="BIG"
        (click)="showUserDetail($event.steamId)"
      ></pydt-player-avatar>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="editingTurnOrder">
  <p style="padding-bottom: 20px; text-align: center">
    Drag and drop the player icons to edit the turn order. The game creator must always be first and can't be moved, and
    all AI players must always be last.
  </p>

  <h4 style="text-align: center">Player Turn Times (in browser local time):</h4>

  <table class="table table-condensed table-striped">
    <thead>
      <tr role="row">
        <th></th>
        <th *ngFor="let hour of hours">
          {{ hour }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of playerUsers$ | async">
        <th>{{ user.displayName }}</th>
        <td
          *ngFor="let hour of userTurnTimesLocal(user)"
          [ngClass]="{ maxHour: hour.isMax }"
          [attr.colspan]="hour.timezoneHour ? 24 : undefined"
        >
          {{ hour.turns }}
          {{ hour.timezoneHour ? "No turn data, using noon in profile timezone (" + hour.timezoneHour + ":00)" : "" }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<div
  bsModal
  #playerDetailModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="playerDetailModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="playerDetailModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">User Info</h4>
      </div>
      <div class="modal-body" style="min-height: 200px">
        <h4 *ngIf="!user">Loading...</h4>
        <ng-container *ngIf="user">
          <pydt-user-info [user]="user"></pydt-user-info>
          <div class="row" style="margin-top: 8px">
            <div class="col-sm-12">
              <pydt-game-detail-change-civ
                *ngIf="canEdit"
                [game]="game"
                [steamId]="user?.steamId"
                [playerCiv]="userCiv"
                [availableCivs]="availableCivs"
                (setGame)="gameUpdated.emit($event)"
              ></pydt-game-detail-change-civ>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <pydt-game-detail-leave
                *ngIf="canEdit && activeProfile?.steamid !== user?.steamId"
                [game]="game"
                [steamId]="user?.steamId"
                (setGame)="gameUpdated.emit($event)"
              ></pydt-game-detail-leave>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="playerDetailModal.hide()">OK</button>
      </div>
    </div>
  </div>
</div>
