<div class="container">
  <div *ngIf="game">
    <h1>Edit Game</h1>
    <form class="form-horizontal" (ngSubmit)="onSubmit()" #editGameForm="ngForm" novalidate="novalidate">
      <pydt-configure-game [game]="game" [model]="model" [selectedCivs]="selectedCivs"></pydt-configure-game>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <button class="btn btn-default" [routerLink]="['/game', game.gameId]" style="margin-right: 10px">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!editGameForm.form.valid">Edit Game</button>
        </div>
      </div>
    </form>
  </div>
</div>
