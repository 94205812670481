<pydt-display-civ [civ]="curCiv" (clicked)="showModal()"></pydt-display-civ>

<div
  bsModal
  #selectCivModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="selectCivModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm" style="width: 500px">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="selectCivModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Select Your Civ</h4>
      </div>
      <div class="modal-body">
        <div style="height: 300px; overflow-y: scroll; margin-bottom: 10px">
          <div *ngFor="let civ of filteredCivs$ | async" [class.selectedCiv]="civ?.leaderKey === curCiv?.leaderKey">
            <pydt-display-civ
              *ngIf="showCiv(civ)"
              [civ]="civ"
              (clicked)="civClicked(civ); selectCivModal.hide()"
            ></pydt-display-civ>
          </div>
        </div>

        <input type="text" class="form-control" [formControl]="civFilter" placeholder="Filter Civs..." />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="selectCivModal.hide()">Cancel</button>
      </div>
    </div>
  </div>
</div>
