<table class="table dataTable" ngClass="{{config.className || ''}}"
        role="grid" style="width: 100%;">
  <thead>
    <tr role="row">
      <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
          (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
        {{column.title}}
        <i *ngIf="config && column.sort" class="pull-right fa"
          [ngClass]="{'fa-chevron-down': column.sort === 'desc', 'fa-chevron-up': column.sort === 'asc'}"></i>
      </th>
    </tr>
  </thead>
  <tbody>
  <tr *ngIf="showFilterRow">
    <td *ngFor="let column of columns">
      <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
              [ngTableFiltering]="column.filtering"
              class="form-control"
              style="width: auto;"
              (tableChanged)="onChangeTable(config)"/>
    </td>
  </tr>
    <tr *ngFor="let row of rows">
      <td (click)="cellClick(row, column.name)" *ngFor="let column of columns">
        <span *ngIf="!column.isComponent" [innerHtml]="sanitize(getData(row, column.name))"></span>
        <ng-container *ngIf="column.isComponent">
          <ng-container *ngComponentOutlet="getData(row, column.name).component; inputs: getData(row, column.name).inputs;" />
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>