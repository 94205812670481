<div class="row">
  <div class="col-md-4">
    <p><b>Current Round:</b> {{ game.round }}</p>
    <pydt-turn-stats [turnData]="game" [displayCharts]="false" [factWidth]="12"></pydt-turn-stats>
  </div>
  <div class="col-md-8">
    <ng-table
      [config]="tableConfig"
      [columns]="tableColumns"
      [rows]="tableData"
      (tableChanged)="onChangeTable(tableConfig, tableData)"
    ></ng-table>
  </div>
</div>
<pydt-turn-stats [turnData]="game" [displayFacts]="false"></pydt-turn-stats>
<div *ngIf="game.gameVideoUrl && isBrowser" class="row">
  <div class="col-md-12">
    <vg-player>
      <video [vgMedia]="media" #media id="replay" preload="auto" controls style="width: 100%">
        <source [src]="game.gameVideoUrl" type="video/webm" />
      </video>
    </vg-player>
  </div>
</div>
