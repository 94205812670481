<div class="row">
  <div class="col-sm-12">
    <p *ngIf="!noOtherPlayers">
      If you no longer wish to play in this game, you can surrender and have an AI player take your place in the game.
      <br /><strong>Please consider requesting substitution if you have a viable position!</strong>
    </p>
    <p *ngIf="noOtherPlayers">
      No one else is left in this game, you should probably just press the End Game button below to end it.
    </p>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 text-center">
    <button type="button" class="btn btn-danger" (click)="confirmSurrenderModal.show()">
      {{ noOtherPlayers ? "End Game" : "Surrender" }}
    </button>
  </div>
</div>

<div
  bsModal
  #confirmSurrenderModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmSurrenderModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="confirmSurrenderModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Are you sure?</h4>
      </div>
      <div class="modal-body">
        Do you really want to {{ noOtherPlayers ? "end" : "surrender from" }} {{ game?.displayName }}?
        <b>THIS ACTION IS IRREVERSIBLE!</b>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="confirmSurrenderModal.hide()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="surrender()">
          {{ noOtherPlayers ? "End Game" : "Surrender!" }}
        </button>
      </div>
    </div>
  </div>
</div>
