<div class="panel panel-default">
  <div class="panel-heading">Change {{ steamId ? "" : "Your" }} Civ</div>
  <div class="panel-body">
    <p *ngIf="!steamId">If you want to change your civ before the game starts, use the selector below...</p>
    <p *ngIf="steamId">Use the selector below to change this player's civ...</p>
    <pydt-select-civ
      [leaders]="availableCivs"
      [randomOnly]="game.randomOnly"
      [curCiv]="playerCiv"
      (selectedCiv)="changeCiv($event)"
    ></pydt-select-civ>
  </div>
</div>
